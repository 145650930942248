import React from "react"
import ResponsiveLayout from "../components/ResponsiveLayout"
import PageTitle from "../components/ResponsiveLayout/pageTitle"
import knappExempelImg from "../Images/medialinq-knapp-exempel.png"
import illustration from "../Images/illustration1_w.jpg"

const Faqs = (props) => {

  let menuPages = [
          { title: "Sidor",
            data:
            [
              { title: "Startsida", data: {href: "/index.html"} },
              { title: "Donator", data: {href: "/donator/index.html"} },
              { title: "Nudgewall", data: {href: "/nudgewall/index.html"} },
              { title: "Nätskribent", data: {href: "/mottagare/index.html"} },
              { title: "Vanliga frågor", data: {href: "/faqs/index.html"} }
            ]
          }
      ]

  return (
      <ResponsiveLayout {...props} pages={menuPages}>
        <PageTitle {...props} pagename={{ short: "Nudgewall", long: "Ökade intäkter för dig som publicerar på nätet. Nudgewall knuffar dina läsare i rätt riktning."
        }}  />
        <div className="container">
          <div className="section-header">
            <h2>Liten knuff till donation utan betalvägg</h2>
            <p>Nätskribenter, bloggare, tidningar och andra innehållsleverantörer på Internet har ett gemensamt problem; Hur kan man få sina läsare att ge ekonomiskt stöd utan att tvinga dem med  en betalvägg? Detta är fullt möjligt att lösa med Nudgewall (knuffvägg) som kan innebära en mindre revolution för dig som är nätskribent. Nu kan du våga be dina läsare om betalt för vissa artiklar eller innehåll utan att det är ett krav.
            </p>
            <p style={{marginTop: "1em"}}>
              Nedan är en demonstration av Nudgewall. Knappen består av två delar; Den vänstra halvan har en donationslänk med uppmaning till stöd, men gratis läsning kan fortfarande ske genom klick på högra halvan. På detta sätt tydliggörs donationsfrågan och läsaren tvingas fatta ett aktivt val. Mer påmind om en donation på ett vänligt sätt kan man inte bli!
            </p>
            <p style={{marginTop: "2em"}}></p>
            <div className="medialinq-nudgewall"
                 data-recipient="f26faaa8-9208-11ea-bb37-0242ac130002"
                 data-nudge="Donera gärna"
                 data-skip="En annan gång, jag läser vidare"
                 data-useicon="true"
                 data-amount="3"
                 data-staticpage="/demo/nudgewalldemo.xhtml"
                 data-benv="1">
              <p style={{marginTop: "2em"}}></p>
              <h2>Vad är Nudgewall?</h2>
              <p>

                Många av de som idag är gratisläsare är beredda att betala för det innehåll de konsumerar, men kan behöva bli påminda om detta och gärna på ett sätt som gör att beslutet om ekonomiskt stöd blir så lätt som möjligt att fatta.
              </p>
              <img style={{marginTop: "2em"}} className="img-fluid" src={illustration} alt="IMG" />

              <p style={{marginTop: "2em"}}>
               Med utgångspunkt från beteendeteorin nudging har vi skapat donationsknappen Nudgewall. Nudging innebär att man gör det lättare för människor att fatta vissa val eller utföra vissa beteenden utan att för den skull ta bort valfriheten eller att tvinga på någon någonting. Utan att vara exkluderande så påminner och förenklar Nudgewall för dina läsare att ge dig det ekonomiska stöd som du så väl förtjänar.
                Med Nudgewall kan du förvandla gratisläsarna till dina sponsorer!
              </p>
              <p style={{marginTop: "2em"}}></p>
              <h2>Vi är ingen vanlig betalförmedlare. Vi hjälper dig till ökade intäkter</h2>
              <p>
                MediaLinq är ett enkelt, smart och snabbt betalsystem för mikrobetalningar. Alla betalningar sammanställs
                månadsvis i ett dokument som blir ditt underlag för vår utbetalning och din redovisning. Mer praktiskt
                kan det inte bli. Vi kan också tillhandahålla motiverande texter och förklaringar som kan hjälpa dig
                när du introducerar MediaLinq.
              </p>
          </div>

          </div>
        </div>
      </ResponsiveLayout>
  )
}

export default Faqs
